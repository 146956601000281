:root.transparent-theme {
	&.highlow {
		$text-color: #393939;

		--bp-font-family: 'Roboto', 'Helvetica', sans-serif;
		--bp-font-size: 15px;
		--bp-font-weight: 300;
		--bp-ling-height: 1.5;
		--bp-color-primary-rgb: 255, 224, 72; //#ffe048
		--bp-color-text-primary-rgb: #{$white-rgb};
		--bp-color-text-secondary-rgb: #{$white-rgb}, 0.5;
		--bp-color-error-rgb: 255, 138, 99;
		--bp-color-success-rgb: 100, 193, 146;
		--bp-input-underline-color-rgb: 115, 115, 115;
		--bp-input-label-text-transform: capitalize;
		--bp-color-dark: #{toRGB($text-color)};

		&.non-production {
			background: #393939;
		}

		.card {
				background: transparent !important;
			box-shadow: none !important;
		}

		.mat-checkbox .mat-checkbox-checkmark-path {
			stroke: $text-color !important;
		}

		.card.payment-card .right .btn-submit {
			color: $text-color !important;
			height: 52px;
			font-size: 13px;
			letter-spacing: 0.075em;
			font-family: Montserrat;
			font-weight: normal;
			text-transform: uppercase;
			border-radius: 0;
			border-bottom: 3px solid #ffad10;
			background: $var-color-primary !important;

			&[disabled] {
				opacity: 0.65;
			}

			&:not([disabled]):hover {
				background: #ffe462 !important;
			}
		}

	}

	&.highlow-v2 {
		--bp-font-family: YakuHanJP_Noto, Roboto, 'Noto Sans JP', 'Hiragino Sans', 'Hiragino Kaku Gothic Pro',
			'ヒラギノ角ゴ Pro W3', '游ゴシック', '游ゴシック体', YuGothic, 'Yu Gothic', 'メイリオ', 'Meiryo',
			'ＭＳ Ｐゴシック', 'MS PGothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		--bp-font-size: 15px;
		--bp-font-weight: 300;
		--bp-ling-height: 1.5;
		--bp-input-label-text-transform: capitalize;
		--bp-color-primary-rgb: 219, 220, 224; // #DBDCE0;
		--bp-color-error-rgb: 248, 57, 86; // #F83956
		--bp-color-text-primary-rgb: 219, 220, 224; // #DBDCE0;
		--bp-color-text-secondary-rgb: 219, 220, 224; // #DBDCE0;
		--bp-form-field-pending-color: rgba(255, 255, 255, 0.2);

		&.non-production {
			background: #1f2024;
		}

		.secure-logos {
			display: none;
		}

		.card,
		.card-container {
			max-width: 750px !important;
		}

		.card {
			border-radius: unset;
			background: transparent;
			box-shadow: none;
			padding: 0;

			form > .row {
				flex-flow: column;
			}

			.left {
				gap: 20px !important;

				> .row {
					flex-flow: row !important;
					gap: 24px !important;
				}

				bp-payment-card-brand-icon {
					bottom: 16px;
				}

				.save-card-checkbox {
					margin-top: -6px !important;
				}
			}

			.right {
				flex-flow: column;
				align-items: center;
				margin: 0 !important;

				bp-page-note {
					order: 2;

					.reach--payment--banner {
						margin: 0;
					}
				}

				.secure-logos {
					order: 1;
					margin: 0 auto;
					flex-flow: wrap-reverse;
					margin-bottom: 20px;
					gap: 0.5rem;

					> img {
						margin: 0 !important;

						&.visa {
							width: 25px !important;
						}
					}
				}

				.credit-detail {
					order: 0;
					margin-top: 24px !important;

					&,
					button {
						width: 100%;
					}
				}
			}

			.btn-submit {
				height: 56px;
				padding: 0;
				margin: 0 !important;
				box-shadow: none !important;
				font-size: 16px;
				line-height: 16px;
				font-weight: 500;
				background: linear-gradient(270deg, #ff664d 0%, #ffd66c 100%) !important;
				border-radius: 28px;
				color: #141517 !important;
				position: relative;
				right: unset;
				bottom: unset;
				top: unset;
				@include inset-inline-start(unset);
				z-index: 0;

				bp-dots-wave {
					display: none;
				}

				&[disabled] {
					opacity: 0.2 !important;
				}
			}

			&.status-card {
				.card-float-button-position {
					bottom: 0;
					justify-content: center;
					display: flex;
				}
			}
		}

		.mat-checkbox .mat-checkbox-inner-container {
			width: 24px;
			height: 24px;
			margin-right: 16px;

			.mat-checkbox-frame {
				background: rgba(0, 0, 0, 0.16);
				border: 2px solid #4f5259;
				box-shadow: inset 4px 4px 3px rgba(0, 0, 0, 0.4);
			}

			.mat-checkbox-background {
				background-color: unset;
				transform: scale(0.6);
			}

			.mat-checkbox-checkmark-path {
				stroke: #ffd66c !important;
				stroke-width: 4px;
			}

			.mat-checkbox-ripple {
				display: none;
			}
		}

		.mat-form-field input {
			font-size: 15px !important;
			line-height: 24px !important;
			font-weight: 400 !important;
			height: 24px !important;
			position: relative;
			top: -2px;

			@include placeholder {
				font-size: 15px !important;
				line-height: 24px !important;
				font-weight: 400 !important;
				color: rgba(255, 255, 255, 0.5);
			}
		}

		bp-credit-card-logo {
			top: 5px !important;
			@include inset-inline-end(0px !important);
		}

		.mat-checkbox-label {
			font-weight: 400;
		}

		.mat-form-field-appearance-outline {
			padding-top: 27px;

			.mat-form-field-outline {
				background: rgba(194, 201, 229, 0.08);
				border-radius: 8px;

				&-start,
				&-gap,
				&-end {
					border-color: transparent !important;
					transition: color, border-color $transition-props;
				}

				&-start {
					border-radius: 8px 0 0 8px;
				}

				&-end {
					border-radius: 0 8px 8px 0;
				}
			}

			.mat-form-field-outline-thick {
				transition-property: box-shadow, color, opacity, background !important;

				.mat-form-field-outline {
					&-start,
					&-gap,
					&-end {
						border-width: 2px;
					}
				}
			}

			.mat-form-field-flex {
				padding: 0 20px;

				.mat-form-field-infix {
					padding: 7px 0 14px 0;
				}
			}

			&:not(.mat-form-field-invalid) .mat-form-field-flex {
				&:hover,
				&:focus-within {
					.mat-form-field-outline-thick {
						background: #3A3D43;
						color: unset !important;
					}
				}
			}

			.mat-form-field-flex {
				&:active,
				&:focus-within {
					.mat-form-field-outline-thick {
						box-shadow: 0px 4px 8px rgba(0,0,0, 0.12), 0px 4px 16px rgba(0,0,0, 0.24);
					}
				}
			}

			&.mat-focused:not(.mat-form-field-invalid) {
				.mat-form-field-outline-thick {
					color: unset !important;
				}

				.mat-form-field-label {
					color: unset !important;
				}
			}

			&.mat-form-field-invalid {
				.mat-form-field-label {
					color: unset;
				}

				.mat-form-field-outline-thick {
					color: #f8395699 !important;
					background: rgba(248, 57, 86, 0.05);
				}

				.mat-form-field-outline {
					&-start,
					&-gap,
					&-end {
						border-color: #f8395699 !important;
					}
				}

				.mat-form-field-flex:hover,
				.mat-form-field-flex:focus-within {
					.mat-form-field-outline-thick {
						color: $var-color-error !important;
						background: rgba(248, 57, 86, 0.1);

					}

					.mat-form-field-outline {
						&-start,
						&-gap,
						&-end {
							border-color: $var-color-error !important;
						}
					}
				}
			}

			.mat-form-field-label-wrapper {
				font-size: 15px;
				top: -155%;
				font-weight: 500;
				@include inset-inline-start(-20px);
				pointer-events: unset;

				.mat-form-field-label {
					transform: unset;
					margin-top: unset;
				}
			}

			.mat-form-field-subscript-wrapper {
				padding: 0;
			}
		}

		.mat-form-field-required-marker {
			display: none;
		}

		bp-processing-stub {
			display: none;
		}

		.saved-payment-cards-autocomplete-panel {
			background: #3A3D43;
			border-radius: 8px;

			.mat-option {
				padding: 0.5rem 1.25rem;
				font-size: 1rem;
				font-weight: 400;
				color:white;

				.card-expiration-date {
					color: #92959A;
					opacity: 1;
				}

				&.mat-selected:not(.mat-active):not(.mat-option-multiple) {
					background: #4f5259;
				}
			}
		}
	}

	&.qoneco {
		--bp-font-weight: 400;
		--bp-font-size: 13px;
		--bp-line-height: 1.5;
		--bp-font-family: 'Nunito', 'Helvetica', sans-serif;
		--bp-color-primary-rgb: 188, 150, 84; //#BC9654
		--bp-color-text-primary-rgb: 102, 102, 102;
		--bp-color-text-secondary-rgb: 102, 102, 102;
		--bp-color-error-rgb: 247, 74, 82;
		--bp-color-success-rgb: 213, 248, 229;
		--bp-input-label-text-transform: capitalize;
		--bp-input-underline-color-rgb: 204, 204, 204;

		$card-bg-color: #fafafa;

		.card.error-card,
		.card.payment-card {
			.mat-checkbox .mat-checkbox-checkmark-path {
				stroke: $card-bg-color !important;
			}

			.btn-submit {
				background: $var-color-primary !important;

				&:not([disabled]):hover {
					background: darken(#bc9654, 10%) !important;
					color: white;
				}
			}
		}
	}

	&.xoro {
		--bp-font-weight: 400;
		--bp-font-size: 13px;
		--bp-line-height: 1.5;
		--bp-font-family: 'Nunito', 'Helvetica', sans-serif;
		--bp-color-primary-rgb: #{toRGB(#0D9488)};
		--bp-color-text-primary-rgb: 102, 102, 102;
		--bp-color-text-secondary-rgb: 102, 102, 102;
		--bp-color-error-rgb: 247, 74, 82;
		--bp-color-success-rgb: 213, 248, 229;
		--bp-input-label-text-transform: capitalize;
		--bp-input-underline-color-rgb: 204, 204, 204;

		$card-bg-color: #fafafa;

		.card.error-card,
		.card.payment-card {
			.mat-checkbox .mat-checkbox-checkmark-path {
				stroke: $card-bg-color !important;
			}

			.btn-submit {
				background: #DCA600 !important;
				border-radius: 83px;

				&:not([disabled]):hover {
					background: darken(#DCA600, 10%) !important;
					color: white;
				}
			}
		}
	}

	&.highlow,
	&.highlow-v2,
	&.qoneco,
	&.xoro {
		main {
			padding: 0 !important;

			.card {
				padding: 0 !important;
			}
		}

		bp-global-spinner,
		bp-header,
		bp-footer,
		.payment-card .title,
		bp-amount {
			display: none !important;
		}

		.secure-logos {
			margin-top: unset !important;

			.visa,
			.master {
				display: none;
			}
		}

		.mat-form-field-infix {
			font-size: 18px !important;
		}

		.mat-form-field .mat-form-field-subscript-wrapper {
			margin-top: 0.7em;
			font-size: 85% !important;

			bp-validation-error {
				font-size: inherit;
			}
		}

		input {
			font-size: 18px !important;
			line-height: 24px !important;
			font-weight: 400 !important;

			@include placeholder {
				font-size: 18px !important;
				line-height: 24px !important;
				font-weight: 400 !important;
			}
		}

		.card.psp-card,
		bp-root {
			padding: 0 !important;
		}

		.card.psp-card {
			box-shadow: none;

			&,
			iframe {
				background: transparent !important;
			}

			iframe {
				border-radius: 0 !important;
				min-height: 330px !important;
			}

			.pay_pal {
				padding: 0;
			}
		}

		.psp-card,
		.status-card {
			max-width: initial;
		}

		.card {
			min-height: 300px !important;
		}

		.card.error-card {
			text-align: center;
		}

		.card.error-card,
		.card.payment-card {
			background: transparent !important;
			box-shadow: none !important;
			padding: 0 !important;
			border: none !important;

			.left {
				@include media('<phone') {
					flex: unset;
				}
			}

			.right {
				@include media('<phone') {
					flex-flow: column-reverse;
				}

				@include media('<tablet') {
					margin-top: 23px;

					.secure-logos {
						margin-bottom: unset;
						flex-flow: row-reverse;
						align-self: center;

						@include media('<phone') {
							@include margin-inline-end(unset);
						}

						img {
							width: 84px;
							@include margin-inline-end(24px);
						}
					}

					.credit-detail {
						margin-top: unset;
					}
				}
			}

			.secure-logos {
				filter: grayscale(1) contrast(0.5) !important;
			}

			.btn-submit {
				position: relative;
				@include margin-inline-start(auto);
				font-weight: 600;
				font-size: 14px;
				@include inset-inline-start(unset);
				@include inset-inline-end(unset);
				bottom: unset;
				box-shadow: none !important;
				z-index: 0;

				@include media('<phone') {
					width: 100%;
					margin-bottom: 24px;
				}

				&[disabled] {
					opacity: 0.65;
				}

				bp-dots-wave {
					display: none;
				}
			}
		}
	}
}
